@use '@/scss/underscore' as _;

.description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: _.unit(0.5);
}

.multiSelect {
  padding-top: _.unit(1);
  padding-left: _.unit(1);
}
