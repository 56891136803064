const roles = {
    page_title: '角色',
    title: '角色',
    subtitle: 'RBAC 是一種訪問控制方法，它使用角色來決定用戶可以做什麼事情，包括授予用戶訪問特定資源的權限。',
    create: '創建角色',
    role_name: '角色名稱',
    role_type: '角色類型',
    role_description: '描述',
    role_name_placeholder: '輸入你的角色名稱',
    role_description_placeholder: '輸入你的角色描述',
    col_roles: '角色',
    col_type: '類型',
    col_description: '描述',
    col_assigned_entities: '已分配',
    user_counts: '{{count}} 位用戶',
    application_counts: '{{count}} 個應用程式',
    user_count: '{{count}} 位用戶',
    application_count: '{{count}} 個應用程式',
    assign_permissions: '分配權限',
    create_role_title: '創建角色',
    create_role_button: '創建角色',
    role_created: '角色 {{name}} 已成功創建。',
    search: '按角色名稱、描述或 ID 搜索',
    placeholder_title: '角色',
    placeholder_description: '角色是可以分配給用戶的權限分組。在創建角色之前，請確保先添加權限。',
    management_api_access_notification: '要訪問Logto管理API，請選擇具有管理API權限的角色<flag/>。',
    with_management_api_access_tip: '此機器對機器角色包含Logto管理API權限',
};
export default Object.freeze(roles);
