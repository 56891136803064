@use '@/scss/underscore' as _;

.createModal {
  .roleTypes {
    margin-top: _.unit(2);
  }
}

.permissions {
  display: flex;
  flex-wrap: wrap;
  gap: _.unit(2);
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
