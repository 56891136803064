@use '@/scss/underscore' as _;

.container {
  font: var(--font-body-2);
}

p {
  margin: 0;
}

p + p {
  margin-top: _.unit(4);
}

.mail {
  font-weight: bold;
}

.tenantList {
  h3 {
    font: var(--font-title-3);
    color: var(--color-text-secondary);
  }
}
