const demo_app = {
    title: 'Вы успешно вошли в живой просмотр!',
    username: 'Имя пользователя: ',
    user_id: 'Идентификатор пользователя: ',
    sign_out: 'Выйти из живого просмотра',
    continue_explore: 'Или продолжить исследование',
    customize_sign_in_experience: 'Настройте опыт входа в систему',
    enable_passwordless: 'Включить безпарольный вход',
    add_social_connector: 'Добавить социальный коннектор',
};
export default Object.freeze(demo_app);
