const demo_app = {
    title: 'Iniciou sessão com sucesso na pré-visualização ao vivo!',
    username: 'Utilizador: ',
    user_id: 'ID de utilizador: ',
    sign_out: 'Terminar sessão na visualização ao vivo',
    continue_explore: 'Ou continue a explorar',
    customize_sign_in_experience: 'Personalizar a experiência de login',
    enable_passwordless: 'Ativar o login sem password (passwordless)',
    add_social_connector: 'Adicionar conector social',
};
export default Object.freeze(demo_app);
